const calculateLayerPosition = (dataObject, layerType = "text") => {
  let videoDim = document.getElementById("fake-video-layer1");
  if(!videoDim){
    videoDim = document.getElementById("fake-video-layer")
  }
  const videoControlBarHeight = 40;
  const {
    x_pos_decimal,
    x_pos_string,
    y_pos_decimal,
    y_pos_string,
    box_width,
    box_height,
    width,
    height,
  } = dataObject;

  let top, left;

  const usedWidth = layerType === "text" ? box_width : width;
  const usedHeight = layerType === "text" ? box_height : height;

  const parentWidth = videoDim.clientWidth;
  const parentHeight = videoDim.clientHeight;

  if (y_pos_string) {
    if (y_pos_string === "center") {
      top = (videoDim.clientHeight - usedHeight) / 2;
    } else if (y_pos_string === "top") {
      top = 0;
    } else if (y_pos_string === "bottom") {
      top = videoDim.clientHeight - usedHeight - videoControlBarHeight;
    }
  } else top = y_pos_decimal * videoDim.clientHeight;

  if (x_pos_string) {
    if (x_pos_string === "center") {
      left = (videoDim.clientWidth - usedWidth) / 2;
    } else if (x_pos_string === "left") {
      left = 0;
    } else if (x_pos_string === "right") {
      left = videoDim.clientWidth - usedWidth;
    }
  } else left = x_pos_decimal * videoDim.clientWidth;

  return {
    parentWidth,
    parentHeight,
    top,
    left,
    width,
    height,
  };
};

export default calculateLayerPosition;
