<template>
  <div
    id="video-player1"
    :class="['video-player1', { 'video-player-view': !isEditPage }]"
  >
    <div @mouseover="setShowIcon(true)" @mouseleave="setShowIcon(false)">
      <transition name="fade">
        <div
          @click="playVideo"
          v-if="showIcon && !isWaiting"
          class="video-player1__player-icons"
        >
          <v-icon color="white" v-if="isPlaying"> mdi-pause </v-icon>
          <v-icon color="white" v-else> mdi-play </v-icon>
        </div>
      </transition>
      <video
        ref="videoPlayer1"
        id="customVideoPlayer1"
        :class="[
          'video-js vjs-chopcast-theme',
          'vjs-16-9',
          { [className]: isEditPage },
        ]"
        @timeupdate="timeupdate"
        preload="metadata"
        crossorigin="anonymous"
      >
        <track
          ref="vttTrack"
          id="trackel"
          kind="captions"
          :src="subtitleVttLink"
          srclang="en"
          label="English"
          default
        />
      </video>
      <div
        id="fake-video-progress1"
        @click="focusFakePlayer"
        :style="{
          position: 'absolute',
          left: 0,
          zIndex: 101,
          alignItems: 'center',
          width: '100%',
          height: '7px',
        }"
      >
        <div
          :style="{
            position: 'relative',
            width: '100%',
            cursor: 'pointer',
            height: '100%',
          }"
        >
          <v-progress-linear
            v-if="videoDuration"
            class="progress-slider"
            color="white"
            :value="videoProgressBar"
            height="7"
            rounded
            @change="getVideoProgressValue"
            @mousemove="getProgressHoverValue"
            @mouseleave="hoveredTime = 0"
          >
            <template v-for="clip in clipsList">
              <div
                v-if="
                  $route.params.clipUuid && clip.uuid === $route.params.clipUuid
                "
                :key="clip.start_time"
                :style="{
                  left: `${(clip.start_time / videoDuration) * 100}%`,
                  width: `${
                    ((clip.end_time - clip.start_time) / videoDuration) * 100
                  }%`,
                }"
                class="highlighted-range"
                style="background-color: orange; opacity: 0.5"
              >
                <div :key="clip.start_time" class="start-bar"></div>
              </div>
              <div
                v-if="!$route.params.clipUuid"
                :key="clip.start_time"
                :style="{
                  left: `${(clip.start_time / videoDuration) * 100}%`,
                  width: `${
                    ((clip.end_time - clip.start_time) / videoDuration) * 100
                  }%`,
                }"
                class="highlighted-range"
                style="background-color: orange; opacity: 0.5"
              >
                <div :key="clip.start_time" class="start-bar"></div>
              </div>
            </template>
          </v-progress-linear>
          <v-progress-linear
            v-if="isEditPage"
            class="progress-slider1"
            :value="fakeProgressValue"
            color="orange"
            height="7"
            rounded
            @change="getProgressLinearValue"
            @mousemove="getHoveredValue"
            @mouseleave="hoveredTime = 0"
          >
            <template v-if="autoCrop && autoCropCuts">
              <template v-for="range in autoCropCuts">
                <div
                  :key="range.start"
                  :style="`left: ${
                    ((range.start - autoCrop.clip_start_time_relatedto_new_buffered_clip) /
                      (autoCrop.clip_end_time_relatedto_new_buffered_clip - autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                    100
                  }%; width: ${
                    ((range.end - range.start) /
                      (autoCrop.clip_end_time_relatedto_new_buffered_clip - autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                    100
                  }%;`"
                  class="highlighted-range"
                ></div>
              </template>
            </template>
            <template v-if="autoCrop && clip.audio_cleaned">
              <template v-for="range in audioFeatureCuts">
                <div
                  :key="range.start"
                  :style="`left: ${
                    ((range.start - autoCrop.clip_start_time_relatedto_new_buffered_clip) /
                      (autoCrop.clip_end_time_relatedto_new_buffered_clip - autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                    100
                  }%; width: ${
                    ((range.end - range.start) /
                      (autoCrop.clip_end_time_relatedto_new_buffered_clip - autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                    100
                  }%;`"
                  class="highlighted-range"
                ></div>
              </template>
            </template>
          </v-progress-linear>
          <div
            style="
              display: flex;
              align-items: center;
              max-width: 90%;
              width: 100%;
            "
          >
            <div @click="playVideo" class="video-player1__progress-icons">
              <v-icon color="white" v-if="isPlaying" size="6px">
                mdi-pause
              </v-icon>
              <v-icon color="white" v-else size="small"> mdi-play </v-icon>
            </div>
            <div
              :style="{
                color: 'white',
                fontSize: '14px',
                padding: '4px',
                minWidth: '100px',
                textAlign: 'center',
                userSelect: 'none',
              }"
              :draggable="false"
            >
              {{ `${playTime()}/${videoTime()}` }}
            </div>
            <div
              id="video-time-tooltip"
              v-if="hoveredTime"
              :style="{
                left: widthValueAtHover + 'px',
                top: '-35px',
                position: 'absolute',
                background: '#030303',
                opacity: '0.8',
                color: 'white',
                zIndex: 200,
                fontSize: '14px',
                padding: '4px',
                borderRadius: '4px',
              }"
            >
              <span>{{ hoveredTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="fake-video-layer1" class="fake-video-layer1" tabindex="1">
        <!-- eslint-disable vue/valid-v-for -->
        <TitleText
          v-for="title in viewedTitles"
          :key="title.uuid + title.font_size"
          :title="title"
          :isEditPage="isEditPage"
          :id="title.uuid"
          :resizeDimension="resizeDimension"
          :isCrop="isCrop"
          :fetchCurrentClip="fetchCurrentClip"
          :resizeCalled="resizeCalled"
          :isFakeVideoLayerFilled="isFakeVideoLayerFilled"
        />
        <!--eslint-enable-->
        <SubtitleText
          v-if="isPlayerRendered && isFakeVideoLayerFilled"
          :text="cuestring"
          :clipStyle="clipStyle"
          :videoDim="vidDim()"
          :createClip="updateCurrentClip"
          :video="video"
          :isEditPage="isEditPage"
          :resizeDimension="resizeDimension"
          :isCrop="isCrop"
          :fetchCurrentClip="fetchCurrentClip"
        />
        <ImageLayer
          v-if="isPlayerRendered && isFakeVideoLayerFilled"
          v-for="image in viewedImageLayers"
          :key="image.uuid"
          :imageLayer="image"
          :isEditPage="isEditPage"
          :resizeDimension="resizeDimension"
          :isCrop="isCrop"
          :fetchCurrentClip="fetchCurrentClip"
          :resizeCalled="resizeCalled"
          :showPlayer="showPlayer"
          :player="player"
        />
        <div id="fake-colored-layer-1"></div>
        <div id="fake-colored-layer-2"></div>
        <div id="center-vertical-line1"></div>
        <div  v-if="isWaiting && !isAutoCropStarted"
              :style="{ position: 'absolute', left:'47%', top:'47%'}"
              class="d-flex align-center justify-center loader-wrapper"
            >
              <v-progress-circular
                :size="46"
                color="blue-grey"
                indeterminate
              ></v-progress-circular>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState, mapActions } from "vuex";
import videojs from "video.js";
import RegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import PlayHeadPlugin from "wavesurfer.js/dist/plugin/wavesurfer.playhead";
import WaveSurfer from "wavesurfer.js";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js"; // used implicitly in the plugin so eslint disabled above (DO NOT REMOVE IT)
import SubtitleText from "./Videos/SubtitleText.vue";
import LoadFontInRunTime from "../utils/LoadFontInRunTime";
import types from "../store/types";
import "video.js/dist/video-js.css";
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import TitleText from "./Videos/TitleText.vue";
import ImageLayer from "./Videos/ImageLayer.vue";
import secondsToMinutes from "../utils/SecondsToTime";
import convertDictionaryToSRT from "../utils/ConvertDictionaryToSRT";

export default {
  name: "VideoPlayer",
  props: {
    getVideoCurrentTime: {
      type: Function,
      default: () => {},
    },
    updateCurrentClip: {
      type: Function,
      default: () => {},
    },
    src: {
      type: String,
      default: "",
    },
    video: {
      type: Object,
    },
    timeRange: {
      type: Object,
    },
    videoDuration: {
      type: Number,
    },
    selectedClip: {
      type: Object,
      default: null,
    },
    clipsList: {
      type: Array,
    },
    videoSubtitle: {
      type: Array,
    },
    clips: {
      type: Array,
      default: () => [],
    },
    videoWidth: {
      type: Number | null,
      default: null,
    },
    videoHeight: {
      type: Number | null,
      default: null,
    },
    playedClipIndex: {
      type: String | Number,
      default: "",
    },
    highlightedRegion: {
      type: Object | null,
      default: null,
    },
    setResizeDimension: {
      type: Function,
      default: () => {},
    },
    setVisibility: {
      type: Function,
      default: () => {},
    },
    resizeDimension: {
      type: String,
      default: "",
    },
    isCrop: {
      type: Boolean,
      default: false,
    },
    setIsCrop: {
      type: Function,
      default: () => {},
    },
    setViewedTimeRange: {
      type: Function,
      default: () => {},
    },
    shouldCallResizeApi: {
      type: Boolean,
      default: false,
    },
    setVideoAspectRatio: {
      type: Function,
      default: () => {},
    },
    fetchCurrentClip: {
      type: Function,
      default: () => {},
    },
    sameCurrentTime: {
      type: Boolean,
      default: false,
    },
    setSameTimeRange: {
      type: Function,
      default: () => {},
    },
    onResizeComplete: {
      type: Function,
      default: () => {},
    },
    showPlayer: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  components: { SubtitleText, TitleText, ImageLayer },
  data() {
    return {
      player: "",
      isPlaying: null,
      isWaiting: true,
      showIcon: true,
      hoveredTime: null,
      widthValueAtHover: 0,
      newXPosition: 0,
      newYPosition: 0,
      resizeCalled: false,
      VUE_APP_BASE_API: process.env.VUE_APP_BASE_API,
      options: {
        controls: true,
        bigPlayButton: false,
        autoplay: false,
        fluid: true,
        loop: false,
        cursor: true,
        regions: true,
        preload: "auto",
        plugins: {},
        errorDisplay: false
      },
      plugins: {
        // configure videojs-wavesurfer plugin
      },
      currentSubtitle: {
        index: -1,
        data: {
          subtitle: "",
          startTime: -1,
          endTime: -1,
        },
      },
      subtitles: {
        font: {
          family: "Satoshi",
          size: 18,
          color: "white",
          bold: false,
          italic: false,
        },
        background: { style: "128", color: "#323232" },
        data: [],
      },
      isPlayerRendered: false,
      isFakeVideoLayerFilled: false,
      videoAspectRatio: "vjs-16-9",
      fakeProgressValue: 0,
      videoProgressBar: 0,
      videoDimensions: {
        width: 0,
        height: 0,
      },
      timer: 0,
      playerContainerDimensions: {
        width: 0,
        height: 0,
      },
      dragingClipStarted: false,
      lastMousePosition: { x: 0, y: 0 },
      titleExamples: [
        {
          font: "Quicksand-Regular",
          color: "#C8A943",
          box_width: 208,
        },
        {
          font: "Abril-Fatface",
          color: "#C87B43",
          box_width: 204,
        },
        {
          font: "Bebas-Neue-Regular",
          color: "#6E43C8",
          box_width: 152,
        },
        {
          font: "Dosis-Bold",
          color: "#3A9B61",
          box_width: 170,
        },
        {
          font: "Josefin-Sans-Bold-Italic",
          color: "#C84343",
          box_width: 204,
        },
        {
          font: "Lato-Black-Italic",
          color: "#2C2C2D",
          box_width: 187,
        },
        {
          font: "Roboto-Medium",
          color: "#4390C8",
          box_width: 203,
        },
      ],
      lastHovered: 0,
      cuestring: "",
    };
  },
  computed: {
    ...mapState({
      fonts: (state) => state.project.fonts,
      clipStyle: (state) => state.project.clipStyle,
      wordsPerLine: (state) => state.project.wordsPerLine,
      subtitleWords: (state) => state.project.subtitleWords,
      titles: (state) => state.project.titles,
      imageLayers: (state) => state.project.imageLayers,
      zoom: (state) => state.video.zoom,
      clip: (state) => state.project.clip,
      reload: (state) => state.project.reload,
      autoCropping: (state) => state.autoCrop.autoCropping,
      showAutoCrop: (state) => state.autoCrop.showAutoCrop,
      autoCrop: (state) => state.autoCrop.autoCropClip,
      autoCropProgress: (state) => state.autoCrop.autoCropProgress,
      isAutoCropStarted: (state) => state.autoCrop.isAutoCropStarted,
    }),
    autoCropCuts(){
      return this.mapMultipleCuts()
    },
    audioFeatureCuts(){
      return this.mapAudioFeatureCuts()
    },
    subtitleVttLink() {
      if (this.video && !this.$route.params.clipUuid) {
        return this.video.web_vtt.vtt_file;
      }
      return `${process.env.VUE_APP_BASE_API}/api/v1/clips/${this.$route.params.clipUuid}/clip_subtitles_vtt/?absoluteTime=true`;
    },
    isVideoPlaying() {
      if (this.player) return this.player.paused();
      return false;
    },
    viewedTitles() {
      return this.titles.filter(
        (title) => title.clip == this.$route.params.clipUuid
      );
    },
    viewedImageLayers() {
      return this.imageLayers.filter(
        (title) => title.clip == this.$route.params.clipUuid
      );
    },
    isEditPage() {
      return (
        this.$route.name.includes("EditClip") ||
        this.$route.name.includes("EditVideo") ||
        this.$route.name.includes("NewClip")
      );
    },
    audioFeatures() {
      if (this.clip.audio_features) {
        let features = Object.keys(this.clip.audio_features);
        const cuts = [];
        features.forEach((k) => {
          this.clip.audio_features[k].forEach((feature) => {
            if (feature) {
              if (
                this.clip.start_time + feature.start < this.clip.end_time &&
                this.clip.start_time + feature.end < this.clip.end_time
              ) {
                cuts.push({
                  start: this.clip.start_time + feature.start,
                  end: this.clip.start_time + feature.end,
                });
              }
            }
          });
        });
        return cuts;
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      fetchFonts: types.project.actions.FETCH_FONTS,
      fetchClipStyle: types.project.actions.FETCH_CLIP_STYLE,
      fetchTextLayer: types.project.actions.GET_TEXT_LAYER,
      fetchImageLayersAction: types.project.actions.FETCH_IMAGE_LAYERS,
      clipResizeCropSquareAction: types.project.actions.CLIP_RESIZE_CROP_SQUARE,
      clipResizeCropVerticalAction:
        types.project.actions.CLIP_RESIZE_CROP_VERTICAL,
      clipResizeFitSquareAction: types.project.actions.CLIP_RESIZE_FIT_SQUARE,
      clipResizeFitVerticalAction:
        types.project.actions.CLIP_RESIZE_FIT_VERTICAL,
      clipResizeCropStandard4by5Action:
        types.project.actions.CLIP_RESIZE_CROP_STANDARD_4_BY_5,
      clipResizeFitStandard4by5Action:
        types.project.actions.CLIP_RESIZE_FIT_STANDARD_4_BY_5,
      clipResizeResetAction: types.project.actions.CLIP_RESIZE_RESET,
      createVideoTitle: types.project.actions.CREATE_TEXT_LAYER,
      fetchClip: types.project.actions.FETCH_CLIP,
      updateClipStyle: types.project.actions.UPDATE_CLIP_STYLE,
      fetchAutocropClip: types.autoCrop.actions.FETCH_AUTO_CROP_CLIP,
    }),
    ...mapMutations({
      setCurrentSubtitle: types.project.mutations.SET_CURRENT_SUBTITLE,
      setNumberOfWordsPerLine: types.project.mutations.SET_WORDS_PER_LINE,
      setVideoSubtitle: types.project.mutations.SET_VIDEO_SUBTITLE,
    }),
    mapMultipleCuts() {
    // Calculate the duration of the first subclip and the second subclip
    const subClipDuration2 = this.autoCrop.clip_end_time_relatedto_new_buffered_clip - this.autoCrop.clip_start_time_relatedto_new_buffered_clip;

    // Initialize an array to hold the mapped cuts for the second subclip
    const mappedCuts = [];
      if(this.clip && this.clip.clip_cuts && this.clip.clip_cuts.length){

    // Iterate over each cut in the first subclip
    for (const cut of this.clip.clip_cuts) {
        const cutStart1 = cut.start;
        const cutEnd1 = cut.end;

        // Calculate the cut duration and its relative start position in the first subclip
        const cutDuration1 = cutEnd1 - cutStart1;
        const cutRelativeStart1 = cutStart1 - this.clip.start_time;

        // Calculate the relative start position of the cut in the second subclip
        const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

        // Calculate the start and end times of the cut in the second subclip
        const cutStart2 = this.autoCrop.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
        const cutEnd2 = cutStart2 + cutDuration1;

        // Add the mapped cut to the array
        mappedCuts.push({ start: cutStart2, end: cutEnd2 });
    }
  }
    return mappedCuts;
},
mapAudioFeatureCuts(){
   // Calculate the duration of the first subclip and the second subclip
   const subClipDuration2 = this.autoCrop.clip_end_time_relatedto_new_buffered_clip - this.autoCrop.clip_start_time_relatedto_new_buffered_clip;

    // Initialize an array to hold the mapped cuts for the second subclip
    const mappedCuts = [];
      if(this.audioFeatures && this.audioFeatures.length){

    // Iterate over each cut in the first subclip
    for (const cut of this.audioFeatures) {
        const cutStart1 = cut.start;
        const cutEnd1 = cut.end;

        // Calculate the cut duration and its relative start position in the first subclip
        const cutDuration1 = cutEnd1 - cutStart1;
        const cutRelativeStart1 = cutStart1 - this.clip.start_time;

        // Calculate the relative start position of the cut in the second subclip
        const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

        // Calculate the start and end times of the cut in the second subclip
        const cutStart2 = this.autoCrop.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
        const cutEnd2 = cutStart2 + cutDuration1;

        // Add the mapped cut to the array
        mappedCuts.push({ start: cutStart2, end: cutEnd2 });
    }
}
return mappedCuts;
},
    vidDim() {
      const vid = document.getElementById("fake-video-layer1");
      return { width: vid.clientWidth, height: vid.clientHeight };
    },
    setVideoRange(startTime, endTime) {
      const currentTime = this.player.currentTime();

      if (
        (this.highlightedRegion &&
          currentTime >= this.highlightedRegion.start &&
          currentTime <= this.highlightedRegion.end) ||
        (currentTime >= startTime && currentTime <= endTime)
      )
        return;
      if (this.isWaiting) {
        return;
      }
      this.player.currentTime(startTime);
      this.player.pause();
      this.isPlaying = false;
    },
    playVideo() {
      if (this.player && !this.player.paused()) {
        this.player.pause();
        this.isPlaying = false;
      } else {
        this.player.play();
        this.isPlaying = true;

        if (this.player.currentTime() - this.timeRange.startTime <= 0) {
          this.fakeProgressValue = 0;
          this.timer = 0;
        }
      }
      this.focusFakePlayer();
    },
    onPressControlBar() {
      document.getElementsByClassName("vjs-control-bar")[0].onclick = () => {
        this.focusFakePlayer();
      };
    },
    focusFakePlayer() {
      document.getElementById("fake-video-layer1").focus();
    },
    getVideoProgressValue(val) {
      this.timeRange;
      const time = (val / 100) * this.videoDuration;
      this.videoProgressBar = val;
      this.setProgressTime(this.lastHovered);
      this.changeCurrentTime(time);
    },
    getProgressLinearValue(val) {
      const { startTime = 0, endTime = this.player.duration() } =
        this.timeRange;
      const time = startTime + (val / 100) * (endTime - startTime);
      this.fakeProgressValue = val;
      this.setProgressTime(this.lastHovered);
      this.changeCurrentTime(time);
    },
    setShowIcon(flag) {
      if (this.isPlaying !== null) this.showIcon = flag;
    },
    timeupdate() {
      let time;
      try {
        if (this.videoDuration && this.$route.params.clipUuid) {
          const { startTime = 0, endTime = this.player.duration() } =
            this.timeRange;
          const currentTime = this.player.currentTime();
          const progress = (currentTime / this.videoDuration) * 100;
          time = 0 + this.player.currentTime();
          this.getVideoCurrentTime(time);
          this.videoProgressBar = progress;
          const calibratedTime = Math.ceil(currentTime);
          if (
            calibratedTime > this.timer &&
            calibratedTime <= this.videoDuration - startTime
          ) {
            this.setProgressTime(calibratedTime);
          } else if (
            calibratedTime > this.timer &&
            calibratedTime >= this.videoDuration - startTime
          ) {
            this.setProgressTime(calibratedTime);
          }
        } else {
          const { startTime = 0, endTime = this.player.duration() } =
            this.timeRange;
          time = this.player.currentTime();
          this.getVideoCurrentTime(time);
          this.fakeProgressValue =
            ((time - startTime) * 100) / (endTime - startTime);
          const calibratedTime = Math.ceil(time - startTime);
          this.videoProgressBar = this.fakeProgressValue;
          if (
            calibratedTime > this.timer &&
            calibratedTime <= endTime - startTime
          )
            this.setProgressTime(calibratedTime);
        }
      } catch (error) {
        return;
      }
      // update current subtitle when video is playing
      // if the time in range subtitle time then set subtitle
      if (this.timeLimit()) {
        // if time > current subtitle endTime then we need to set next subtitle or empty string (gap)
        if (
          this.currentSubtitle.data.startTime > time ||
          this.currentSubtitle.data.endTime < time
        ) {
          //  if time > next subtitle, set current subtitle to next subtitle

          // we use spread operator to have a copy and don't change original subtitle in else block
          const newData = this.subtitles.data.find(
            (subtitle) => subtitle.startTime <= time && subtitle.endTime >= time
          );

          if (newData) {
            this.currentSubtitle.data = { ...newData };
          } else {
            this.resetCurrentSubtitle();
          }
        }
      } else {
        // if time not in subtitle range reset current subtitle to default
        this.resetCurrentSubtitle();
      }
    },
    seekToTime(time) {
      this.changeCurrentTime(time);
    },
    changeCurrentTime(time) {
      this.player.currentTime(time);
    },
    timeLimit(currentTime) {
      const time =
        currentTime != undefined ? currentTime : this.player.currentTime();
      // if (!this.isPlaying) return;
      const length = this.subtitles.data.length;
      if (!length) return;
      return (
        time >= this.subtitles.data[0].startTime &&
        ((this.subtitles.data[length - 1].endTime &&
          time < this.subtitles.data[length - 1].endTime) ||
          this.subtitles.data[length - 1].startTime)
      );
    },
    resetCurrentSubtitle() {
      if (this.currentSubtitle.data.subtitle !== "") {
        this.currentSubtitle = {
          index: -1,
          data: {
            subtitle: "",
            startTime: -1,
            endTime: -1,
          },
        };
      }
    },
    updateRegions() {
      let clipSubtitle = "";
      let regions = [];
      const { name, params } = this.$route;
      if (name.includes("EditClipForVideo")) {
        const currentClip =
          this.playedClipIndex != null
            ? this.clips[this.playedClipIndex]
            : this.clips.find((clip) => clip.uuid === params.clipUuid);
        this.subtitleWords.forEach((subtitle, i) => {
          if (
            subtitle.startTime >= currentClip.start_time &&
            subtitle.startTime <= currentClip.end_time
          )
            clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}"> ${subtitle.word}</span>`;
          else {
            clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}" contentEditable="false" style="text-decoration:line-through;color:#d6d8d9; text-decoration-color: #d6d8d9;"> ${subtitle.word}</span>`;
          }
        });
        regions = [
          {
            id: currentClip.uuid,
            start: currentClip.start_time,
            end: currentClip.end_time,
            color: "#FDF6E0",
          },
        ];
      } else if (
        name.includes("EditVideoOnly") ||
        name.includes("NewClipForVideo")
      ) {
        this.subtitleWords.forEach((subtitle, i) => {
          clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}"> ${subtitle.word}</span>`;
        });
        regions = [
          {
            id: this.video.uuid,
            start: 0,
            end: this.video.duration,
            color: "rgba(206, 159, 252, 0.67)",
          },
        ];
      }

      this.setCurrentSubtitle(clipSubtitle);
      return regions;
    },
    setAspectRatio() {
      const width = this.videoWidth;
      const height = this.videoHeight;
      let ratio = "16:9";
      let classRatio = "vjs-16-9";

      if (width && height) {
        if (width / height === 16 / 9) {
          ratio = "16:9";
          classRatio = "vjs-16-9";
        } else if ((width / height).toFixed(2) === (9 / 16).toFixed(2)) {
          ratio = "9:16";
          classRatio = "vjs-9-16";
        } else if (width === height) {
          ratio = "1:1";
          classRatio = "vjs-1-1";
        }
      }

      this.player.aspectRatio(ratio);
      this.videoAspectRatio = classRatio;
      this.setVideoAspectRatio(classRatio);
      if (this.isEditPage) {
        this.setVisibility();
      } else {
        this.setResizeDimension(classRatio, false);
      }
    },
    checkClipResizing() {
      const { clipUuid } = this.$route.params;
      if (clipUuid) {
        const currentClip = this.clips.find((clip) => clip.uuid == clipUuid);

        let ratio = "16:9";
        let classRatio = "vjs-16-9";
        let isCrop = false;

        if (currentClip.format != this.video.format) {
          if (
            currentClip.video_format === "horizontal" ||
            !currentClip.video_format
          ) {
            ratio = "16:9";
            classRatio = "vjs-16-9";
          } else if (
            currentClip.video_format === "vertical" ||
            currentClip.original_video_format === "vertical"
          ) {
            ratio = "9:16";
            classRatio = "vjs-9-16";
          } else if (currentClip.video_format === "square") {
            ratio = "1:1";
            classRatio = "vjs-1-1";
          } else if (currentClip.video_format === "standard") {
            ratio = "4:5";
            classRatio = "vjs-4-5";
          }

          isCrop =
            currentClip.resize_type == "crop" ||
            currentClip.original_video_format === "vertical";
        }

        this.player.aspectRatio(ratio);
        if (this.isEditPage) {
          this.setVisibility();
        } else {
          this.setResizeDimension(classRatio, false);
        }
        this.setIsCrop(isCrop);
        this.computeResizedVideoDimensions(classRatio, isCrop);
      }
    },
    fillSubtitles() {
      if (this.currentSubtitle.data.subtitle === "" && !this.isPlaying) {
        if (
          this.clip &&
          this.$route.params.clipUuid &&
          this.subtitleWords &&
          this.subtitleWords.length
        ) {
          const data = this.subtitleWords.filter(
            (s) =>
              s.startTime >= this.clip.start_time &&
              s.startTime <= this.clip.end_time
          );

          if (
            this.autoCrop.clip_cuts &&
            this.autoCrop.clip_cuts.length
          ) {
            const filteredSubtitles = data.filter(
              (subtitle) =>
                !this.isSubtitleWithinCuts(subtitle, this.autoCrop.clip_cuts)
            );
            const formattedSubtitles = convertDictionaryToSRT(
              filteredSubtitles,
              this.wordsPerLine
            );
            if (!formattedSubtitles[formattedSubtitles.length - 1].endTime) {
              formattedSubtitles[formattedSubtitles.length - 1]["endTime"] =
                formattedSubtitles[formattedSubtitles.length - 1].startTime +
                100;
            }
            this.subtitles.data = formattedSubtitles;
          } else {
            const formattedSubtitles = convertDictionaryToSRT(
              data,
              this.wordsPerLine
            );
            if (!formattedSubtitles[formattedSubtitles.length - 1].endTime) {
              formattedSubtitles[formattedSubtitles.length - 1]["endTime"] =
                formattedSubtitles[formattedSubtitles.length - 1].startTime +
                100;
            }
            this.subtitles.data = formattedSubtitles;
          }
        } else {
          this.subtitles.data = this.videoSubtitle || [];
        }
        // when user change video time we need to set the current subtitle to new subtitle or gap
        this.timeupdate();
      }
    },
    checkSubtitleTextReady() {
      return this.$route.params.clipUuid
        ? this.currentSubtitle.data.subtitle.length &&
            this.clipStyle &&
            Object.keys(this.clipStyle).length
        : this.currentSubtitle.data.subtitle.length;
    },
    loadVideoAndRegions() {
      if (!this.currentSubtitle?.data?.subtitle && !this.player) {
        const formattedClips = this.updateRegions();
        if (!this.$refs.videoPlayer1) return;

        this.player = videojs(this.$refs.videoPlayer1, this.options, () => {
          this.player.src({
            src: `${this.src}#=${this.timeRange.startTime},${this.timeRange.endTime}`,
            type: "video/mp4",
          });
          this.player.fluid(true);
        });
        this.player.on("loadedmetadata", () => {
          this.setAspectRatio();
          this.getPlayerContainerDimensions();
          this.createFakeVideoLayer();
          this.checkClipResizing();
          this.setProgressTime();
          this.onPressControlBar();
          this.setChangeCroppedClipPositionEvents();
          this.isWaiting = false;
        });
        this.player.on("ready", () => {
          // below styling the text track
          // allowed styles are here: https://docs.videojs.com/tracks_text-track-settings.js
          // const settings = this.player.textTrackSettings;
          // settings.setValues({
          //   backgroundColor: this?.clipStyle?.background_color,
          //   fontFamily: this?.clipStyle?.font.split("-")[0],
          // });
          // settings.updateDisplay();
          setTimeout(() => {
            const track = this.player.textTracks().tracks_[0];
            track.mode = "hidden";
            this.player
              .textTracks()
              .tracks_[0].addEventListener("cuechange", () => {
                const currentCue = track.activeCues[0];
                if (currentCue) {
                  const tt = currentCue.text
                    .replace(/<c.custom>/g, '<span class="custom">')
                    .replace(/<\/c>/g, "</span>");
                  this.cuestring = tt;
                } else {
                  this.cuestring = ""; // No active cue, clear the container
                }
              });
          }, 1000);
          // .addEventListener('cuechange', ()=>{
          //   console.log("809")
          // })
        }),
        this.player.on("canplay", () => {
          this.setFakeProgressbarPosition(0);
          this.$nextTick(()=>{
            this.focusFakePlayer();
          })
         const customPlayer = document.getElementById("customVideoPlayer1")
         const bar = customPlayer.querySelector('.vjs-control-bar')
         bar.style.display = 'flex';
         bar.style.visibility = "visible"
        });
        this.player.on("play", () => {
          this.isPlaying = true;
        });
        this.player.on("pause", () => {
          this.isPlaying = false;
        });
        this.player.on("seeked", () => {
          const { startTime, endTime } = this.timeRange;
          this.setVideoRange(startTime, endTime);
        });
        this.player.on("timeupdate", () => {
          const { startTime, endTime } = this.timeRange;
          // checking if video has cuts then skip that part of video to make trim video by text functionality working in video preview
          if (
            this.autoCropCuts &&
            this.autoCropCuts.length
          ) {
            let cuts = this.autoCropCuts;
            for (let i = 0; i < cuts.length; i++) {
              if (
                cuts[i].start - 0.2 <= this.player.currentTime().toFixed(3) &&
                this.player.currentTime().toFixed(3) <= cuts[i].start + 0.2
              && this.isPlaying) {
                this.player.pause();
                this.player.currentTime(
                  (cuts[i].end - cuts[i].start) * 1000 < 250
                    ? cuts[i].end + 0.23
                    : cuts[i].end
                );
                this.player.play();
              }
            }
          }
          if (
            this.clip &&
            this.clip.audio_cleaned &&
            this.audioFeatureCuts.length && this.isPlaying
          ) {
            let cuts = this.audioFeatureCuts;
            for (let i = 0; i < cuts.length; i++) {
              if (
                cuts[i].start - 0.2 <= this.player.currentTime().toFixed(3) &&
                this.player.currentTime().toFixed(3) <= cuts[i].start + 0.2
              ) {
                this.player.pause();
                this.player.currentTime(
                  (cuts[i].end - cuts[i].start) * 1000 < 250
                    ? cuts[i].end + 0.23
                    : cuts[i].end
                );
                this.player.play();
              }
            }
          }
          this.setVideoRange(startTime, endTime);
        });
      }
    },
    createFakeVideoLayer() {
      const fakeVideo = document.getElementById("fake-video-layer1");
      const customVideoPlayer = document.getElementById(
        "customVideoPlayer1_html5_api"
      );

      let realVideoWidth;
      let realVideoHeight;
      if(this.player.videoWidth() > this.player.videoHeight()){
        realVideoWidth = this.player.videoWidth();
        realVideoHeight = this.player.videoHeight();
      }
      else if(this.player.videoWidth() === this.player.videoHeight()){
        realVideoWidth = this.player.videoWidth() + this.player.videoWidth();
        realVideoHeight = this.player.videoHeight();
      }
      else{
        realVideoWidth = this.player.videoHeight();
        realVideoHeight = this.player.videoWidth();
      }
      const domVideoHeight = customVideoPlayer.clientHeight;
      const domVideoWidth =
        !this.$route.params.clipUuid &&
        this.video.original_video_format !== "vertical"
          ? customVideoPlayer.clientWidth
          : (domVideoHeight * realVideoWidth) / realVideoHeight;

      this.setFakeVideoDimension(domVideoWidth, domVideoHeight);
      this.isFakeVideoLayerFilled = true;
      this.videoDimensions = {
        width: domVideoWidth,
        height: domVideoHeight,
      };

      fakeVideo.onclick = () => {
        if (!this.isEditPage) {
          this.playVideo();
        }

        this.focusFakePlayer();
      };
    },
    getPlayerContainerDimensions() {
      const customVideoPlayerContainer =
        document.getElementById("customVideoPlayer1");

      this.playerContainerDimensions = {
        width: customVideoPlayerContainer.clientWidth,
        height: customVideoPlayerContainer.clientHeight,
      };
    },
    setPlayerContainerDimensions(width, height) {
      if (width && height) {
        const videoPlayerContainer =
          document.getElementById("customVideoPlayer1");
        videoPlayerContainer.style.height = height + "px";
        videoPlayerContainer.style.width = width + "px";

        this.setFakeProgressbarPosition(width);
      }
    },
    setColoredLayersDimensions(fakeVideoWidth, fakeVideoHeight) {
      const { clipUuid } = this.$route.params;
      if (clipUuid) {
        const coloredLayer1 = document.getElementById("fake-colored-layer-1");
        const coloredLayer2 = document.getElementById("fake-colored-layer-2");
        const currentClip = this.clips.find((clip) => clip.uuid == clipUuid);

        const [_, widthRatio, heightRatio] = this.videoAspectRatio.split("-");
        const actualVideoHeight = fakeVideoWidth / (widthRatio / heightRatio);
        const height =
          fakeVideoHeight == 0 || this.isCrop
            ? 0
            : (fakeVideoHeight - actualVideoHeight) / 2;

        coloredLayer1.style.height = height + "px";
        coloredLayer2.style.height = height + "px";
        coloredLayer2.style.bottom = "0px"; // compensate removing control bar height
        coloredLayer1.style.width = fakeVideoWidth + "px";
        coloredLayer2.style.width = fakeVideoWidth + "px";

        coloredLayer1.style.backgroundColor = currentClip.fit_color;
        coloredLayer2.style.backgroundColor = currentClip.fit_color;
      }
    },
    setFakeProgressbarPosition(width) {
      const videoPlayerContainer =
        document.getElementById("customVideoPlayer1");

      const fakeVideoprogressbar = document.getElementById(
        "fake-video-progress1"
      );
      const videoPlayerLeftContainer =
        videoPlayerContainer.getBoundingClientRect().left;
      const videoPlayerLeft = document
        .getElementById("video-player1")
        .getBoundingClientRect().left;
      fakeVideoprogressbar.style.width =
        videoPlayerContainer.getBoundingClientRect().width + "px";
      fakeVideoprogressbar.style.left =
        videoPlayerLeftContainer - videoPlayerLeft + "px";
    },
    setFakeVideoDimension(width, height) {
      const fakeVideo = document.getElementById("fake-video-layer1");

      fakeVideo.style.height = height + "px"; // remove control bar height
      fakeVideo.style.width = width + "px";

      if (this.resizeDimension == this.videoAspectRatio) {
        if (this.video.original_video_format === "vertical") {
          this.setPlayerContainerDimensions(width, height);
        } else {
          this.setPlayerContainerDimensions(
            this.playerContainerDimensions.width,
            this.playerContainerDimensions.height
          );
          this.setColoredLayersDimensions(0, 0);
        }
      } else {
        this.setPlayerContainerDimensions(width, height);
        this.setColoredLayersDimensions(width, height);
      }
    },
    handleShortcutPress() {
      document.body.onkeydown = (e) => {
        if (document?.activeElement?.id === "fake-video-layer1") {
          if (e.code == "Space") {
            e.preventDefault();
            this.playVideo();
          } else if (e.code == "ArrowRight") {
            e.preventDefault();
            const time = this.player.currentTime() + 10;

            if (this.timeRange.endTime >= time) {
              this.seekToTime(time);
            } else {
              this.seekToTime(this.timeRange.endTime);
            }
          } else if (e.code == "ArrowLeft") {
            e.preventDefault();
            const time = this.player.currentTime() - 10;

            if (this.timeRange.startTime <= time) {
              this.seekToTime(time);
            } else {
              this.seekToTime(this.timeRange.startTime);
            }
          } else if (e.code == "ArrowUp") {
            e.preventDefault();
            this.player.volume(this.player.volume() + 0.1);
          } else if (e.code == "ArrowDown") {
            e.preventDefault();
            this.player.volume(this.player.volume() - 0.1);
          }
        }
      };
    },
    videoTime() {
      if (!this.player || isNaN(this.player.duration())) return "00:00";
      const { startTime = 0, endTime = this.player.duration() } =
        this.timeRange;
      if (this.videoDuration && this.$route.params.clipUuid) {
        return secondsToMinutes(this.videoDuration);
      }
      return secondsToMinutes(endTime - startTime);
    },
    playTime() {
      return secondsToMinutes(this.timer);
    },
    getProgressHoverValue(e) {
      const timeLineWidth = document
        .querySelector("#fake-video-progress1 div")
        .getBoundingClientRect().width;
      this.widthValueAtHover = e.offsetX;
      if (this.widthValueAtHover < 0) this.widthValueAtHover = 0;
      const val = this.widthValueAtHover / timeLineWidth;
      const vidTime = this.videoDuration;
      this.hoveredTime = secondsToMinutes(vidTime * val);
      this.lastHovered = vidTime * val;
    },
    getHoveredValue(e) {
      const timeLineWidth = document
        .querySelector("#fake-video-progress1 div")
        .getBoundingClientRect().width;
      this.widthValueAtHover = e.offsetX;
      if (this.widthValueAtHover < 0) this.widthValueAtHover = 0;
      const val = this.widthValueAtHover / timeLineWidth;
      const { startTime, endTime } = this.timeRange;
      const vidTime = endTime - startTime;
      this.hoveredTime = secondsToMinutes(vidTime * val);
      this.lastHovered = vidTime * val;
    },
    setProgressTime(time) {
      if (!time) this.timer = 0;
      else {
        this.timer = time;
      }
    },
    async onResizeVideo(resizeDimension, isCrop) {
      const { clipUuid } = this.$route.params;
      const payload = {
        clipUuid,
        max_words_per_line: 4,
      };
      if (resizeDimension === "vjs-16-9" && this.$route.params.clipUuid) {
        // horizontal video
        await this.updateClipStyle(payload);
      } else {
        if (this.$route.params.clipUuid) {
          await this.updateClipStyle({ ...payload, max_words_per_line: 3 });
        }
      }
      // this.createVideoTitle({
      //   clipUuid,
      //   font: "Quicksand-Regular",
      //   color: "#C8A943",
      //   box_width: 208,
      // });
      const customVideoPlayer = document.getElementById(
        "customVideoPlayer1_html5_api"
      );

      let resizeAction = this.clipResizeResetAction;
      if (resizeDimension != this.videoAspectRatio) {
        this.computeResizedVideoDimensions(resizeDimension, isCrop);
        if (isCrop) {
          customVideoPlayer.style.objectFit = "cover";
          if (resizeDimension == "vjs-1-1") {
            resizeAction = this.clipResizeCropSquareAction;
          } else if (resizeDimension == "vjs-9-16") {
            resizeAction = this.clipResizeCropVerticalAction;
          } else if (resizeDimension == "vjs-4-5") {
            resizeAction = this.clipResizeCropStandard4by5Action;
          }
        } else {
          customVideoPlayer.style.objectFit = "cover";
          if (resizeDimension == "vjs-1-1") {
            resizeAction = this.clipResizeFitSquareAction;
          } else if (resizeDimension == "vjs-9-16") {
            resizeAction = this.clipResizeFitVerticalAction;
          } else if (resizeDimension == "vjs-4-5") {
            resizeAction = this.clipResizeFitStandard4by5Action;
          }
        }
      } else if (this.video.original_video_format === "vertical") {
        const fakeVideo = document.getElementById("fake-video-layer1");
        this.setPlayerContainerDimensions(
          fakeVideo.style.width,
          fakeVideo.style.height
        );
      } else {
        customVideoPlayer.style.height = "100%";
        customVideoPlayer.style.width = "100%";
        customVideoPlayer.style.objectFit = "contain";
        customVideoPlayer.style.borderRadius = "8px";
        customVideoPlayer.style.objectPosition = "center";

        this.createFakeVideoLayer();
        this.setPlayerContainerDimensions(
          this.playerContainerDimensions.width,
          this.playerContainerDimensions.height
        );
        this.setColoredLayersDimensions(0, 0);
      }

      if (this.shouldCallResizeApi) {
        this.resizeCalled = false;
        resizeAction({
          uuid: clipUuid,
          x: 0,
          format: this.resizeDimension,
        }).then(async () => {
          this.fetchClipLayers(true);
          const { clipUuid } = this.$route.params;
          await this.fetchClip({
            clipUuid: clipUuid,
            ratio: this.autoCropping ? this.autoCropping.dimension : null,
          });
          this.resizeCalled = true;
          this.onResizeComplete(resizeDimension);
        });
      }
    },
    computeResizedVideoDimensions(resizeDimension, isCrop) {
      const customVideoPlayer = document.getElementById(
        "customVideoPlayer1_html5_api"
      );
      const [_, widthRatio, heightRatio] = resizeDimension.split("-");
      const ratio = Number(widthRatio) / Number(heightRatio);
      const width = this.videoDimensions.width;
      const height = this.videoDimensions.height;

      customVideoPlayer.style.borderRadius = "0";

      let newHeight = width / ratio;
      let newWidth = width;

      if (width > height) {
        newHeight = height;
        newWidth = ratio * height;
      }

      customVideoPlayer.style.height = newHeight + "px";
      customVideoPlayer.style.width = newWidth + "px";
      this.setFakeVideoDimension(newWidth, newHeight);

      if (isCrop) {
        customVideoPlayer.style.objectFit = "cover";
        let xPosition = 0;
        const yPosition = 0;
        if (this.clip && this.clip.x) {
          xPosition =
            -(this.clip.x / this.player.videoWidth()) *
            this.videoDimensions.width;
        }
        const clip = this.clips.find(
          (c) => c.uuid === this.$route.params.clipUuid
        );
        if (clip) {
          xPosition = clip.x;
        }
        customVideoPlayer.style.objectPosition = "center";
      } else {
        customVideoPlayer.style.objectFit = "contain";
        customVideoPlayer.style.objectPosition = "center";
      }
    },
    fetchClipLayers(noLoading = false) {
      const { clipUuid } = this.$route.params;
      this.fetchClipStyle({ uuid: clipUuid, noLoading });
      this.fetchTextLayer(clipUuid);
      this.fetchImageLayersAction(clipUuid);
    },
    setChangeCroppedClipPositionEvents() {
      setTimeout(() => {
        const customVideoPlayer = document.getElementById(
          "customVideoPlayer1_html5_api"
        );
        if (this.isCrop) {
          if (this.clip) {
            const { x } = this.clip;
            if (x > 0) {
              let x =
                (this.clip.x / this.player.videoWidth()) *
                this.videoDimensions.width;
              customVideoPlayer.style.objectPosition = "center";
            } else if (x === 0) {
              customVideoPlayer.style.objectPosition = "center";
            }
          } else {
            const clip = this.clips.find(
              (c) => c.uuid === this.$route.params.clipUuid
            );
            if (clip) {
              const { x } = clip;
              if (x > 0) {
                let x =
                  (this.clip.x / this.player.videoWidth()) *
                  this.videoDimensions.width;
                customVideoPlayer.style.objectPosition = "center";
              } else if (x === 0) {
                customVideoPlayer.style.objectPosition = "center";
              }
            }
          }
        }
      }, 500);
      this.onMouseDown();
      this.onMouseUp();
      this.onMouseMove();
    },
    onMouseDown() {
      const containerOffset = document
        .getElementById("customVideoPlayer1_html5_api")
        .getBoundingClientRect();

      document
        .getElementById("fake-video-layer1")
        .addEventListener("mousedown", (event) => {
          event.preventDefault();
          if (containerOffset.width && containerOffset.height) {
            this.dragingClipStarted = true;
            this.lastMousePosition = {
              x: event.pageX,
              y: event.pageY,
            };
          }
        });
    },
    onMouseUp() {
      document
        .getElementById("fake-video-layer1")
        .addEventListener("mouseup", async () => {
          let x = Math.abs(this.newXPosition / this.videoDimensions.width);
          this.dragingClipStarted = false;
          const { clipUuid } = this.$route.params;

          if (this.isCrop && this.clip.video_format === "square") {
            await this.clipResizeCropSquareAction({ uuid: clipUuid, x: x });
            this.fetchClip({
              clipUuid: clipUuid,
              ratio: this.autoCropping ? this.autoCropping.dimension : null,
            });
            this.fetchCurrentClip();
          }
          if (
            this.isCrop &&
            this.clip.video_format === "vertical" &&
            this.clip.original_video_format !== "vertical"
          ) {
            await this.clipResizeCropVerticalAction({
              uuid: clipUuid,
              x: x,
              format: this.resizeDimension,
            });
            this.fetchClip({
              clipUuid: clipUuid,
              ratio: this.autoCropping ? this.autoCropping.dimension : null,
            });
            this.fetchCurrentClip();
          }
          if (this.isCrop && this.clip.video_format === "standard") {
            await this.clipResizeCropStandard4by5Action({
              uuid: clipUuid,
              x: x,
              format: this.resizeDimension,
            });
            this.fetchClip({
              clipUuid: clipUuid,
              ratio: this.autoCropping ? this.autoCropping.dimension : null,
            });
            this.fetchCurrentClip();
          }
          // this.fetchCurrentClip();
        });
      document.body.addEventListener("mouseup", () => {
        if (this.dragingClipStarted) {
          this.dragingClipStarted = false;
        }
      });
    },
    onMouseMove() {
      document
        .getElementById("fake-video-layer1")
        .addEventListener("mousemove", (event) => {
          event.preventDefault();
          if (
            this.dragingClipStarted &&
            this.isCrop &&
            this.resizeDimension != this.videoAspectRatio
          ) {
            const customVideoPlayer = document.getElementById(
              "customVideoPlayer1_html5_api"
            );
            const containerRect = customVideoPlayer.getBoundingClientRect();

            const current_mouse_position = {
              x: event.pageX,
              y: event.pageY,
            };
            const change_x =
              current_mouse_position.x - this.lastMousePosition.x;
            const change_y =
              current_mouse_position.y - this.lastMousePosition.y;

            this.lastMousePosition = { ...current_mouse_position };

            const [xPosition, yPosition] =
              customVideoPlayer.style.objectPosition.split(" ");
            const oldXPosition = parseFloat(
              xPosition.slice(0, xPosition.length - 2)
            );
            const oldYPosition = yPosition
              ? parseFloat(yPosition.slice(0, yPosition.length - 2))
              : 0;

            let newXPosition = oldXPosition + change_x;
            let newYPosition = oldYPosition + change_y;

            if (newYPosition > 0) {
              newYPosition = 0;
            }
            if (
              containerRect.height - newYPosition >
              this.videoDimensions.height
            ) {
              newYPosition = containerRect.height - this.videoDimensions.height;
            }

            if (newXPosition > 0) {
              newXPosition = 0;
            }
            if (
              containerRect.width - newXPosition >
              this.videoDimensions.width
            ) {
              newXPosition = containerRect.width - this.videoDimensions.width;
            }

            customVideoPlayer.style.objectPosition = "center";
            this.newXPosition = newXPosition;
            this.newYPosition = newYPosition;
          }
        });
    },
    loadInitialFonts() {
      this.titleExamples.forEach((example) => {
        const fontFamilyURL = this.fonts.find(
          (font) => font.name === example.font
        ).url;
        LoadFontInRunTime(example.font, fontFamilyURL);
      });
    },
    isSubtitleWithinCuts(subtitle, cuts) {
      return cuts.some(
        (cut) =>
          subtitle.startTime >= cut.start && subtitle.startTime <= cut.end
      );
    },
  },
  async mounted() {
    this.handleShortcutPress();
    if (this.$route.params.clipUuid && this.clip) {
      const data = this.subtitleWords.filter(
        (s) =>
          s.startTime >= this.clip.start_time &&
          s.startTime <= this.clip.end_time
      );
      if (
        this.autoCrop &&
        this.autoCrop.clip_cuts &&
        this.autoCrop.clip_cuts.length
      ) {
        const filteredSubtitles = data.filter(
          (subtitle) =>
            !this.isSubtitleWithinCuts(subtitle, this.autoCrop.clip_cuts)
        );
        const formattedSubtitles = convertDictionaryToSRT(
          filteredSubtitles,
          this.wordsPerLine
        );
        if (!formattedSubtitles[formattedSubtitles.length - 1].endTime) {
          formattedSubtitles[formattedSubtitles.length - 1]["endTime"] =
            formattedSubtitles[formattedSubtitles.length - 1].startTime + 100;
        }
        this.subtitles.data = formattedSubtitles;
      } else {
        const formattedSubtitles = convertDictionaryToSRT(
          data,
          this.wordsPerLine
        );
        if (!formattedSubtitles[formattedSubtitles.length - 1].endTime) {
          formattedSubtitles[formattedSubtitles.length - 1]["endTime"] =
            formattedSubtitles[formattedSubtitles.length - 1].startTime + 100;
        }
        this.subtitles.data = formattedSubtitles;
      }
    } else {
      this.subtitles.data = this.videoSubtitle || [];
    }

    const { clipUuid } = this.$route.params;
    if (clipUuid) {
      this.fetchClipLayers();
    }

    if (this.fonts.length && this.$route.params.clipUuid) {
      // TODO Fix font family by place "Roboto" should be removed later
      const fontFamily = this.clipStyle.font || "Roboto";
      const fontFamilyURL = this.fonts.filter(
        (font) => font.name === fontFamily
      )[0].url;
      LoadFontInRunTime(fontFamily, fontFamilyURL);
    } else {
      LoadFontInRunTime(
        "Arvo-Bold",
        "https://fonts.gstatic.com/s/arvo/v14/tDbM2oWUg0MKoZw1-LPK8w.woff2"
      );
    }
    await this.fetchFonts();
    this.isPlayerRendered = true;
    this.loadVideoAndRegions();
    this.loadInitialFonts();
  },
  updated() {
    this.loadVideoAndRegions();
    this.fillSubtitles();
    if (
      this.resizeDimension == this.videoAspectRatio &&
      !this.isFakeVideoLayerFilled
    ) {
      this.createFakeVideoLayer();
    }
  },
  watch: {
    videoWidth() {
      if (this.player) {
        this.setAspectRatio();
      }
    },
    $route() {
      const { path, params } = this.$route;

      if (params.clipUuid) {
        this.fetchClipLayers();
        setTimeout(() => {
          this.checkClipResizing();
        }, 0);
      }

      if (
        path.includes("subtitles") ||
        path.includes("text") ||
        path.includes("images") ||
        path.includes("templates") ||
        path.includes("elements")
      )
        return;
    },
    timeRange() {
      if (!this.sameCurrentTime) {
        this.resetCurrentSubtitle();
        const { startTime } = this.timeRange;

        if (this.player) {
          this.player.pause();
          this.player.currentTime(startTime);

          this.fakeProgressValue = 0;
          this.timer = 0;
        }
        this.setSameTimeRange(false);
      }
    },
    highlightedRegion() {},
    resizeDimension(newValue) {
      this.onResizeVideo(newValue, this.isCrop);
    },
    autoCropProgress(newValue){
      if(newValue === 100){
        this.onResizeVideo(this.resizeDimension, true);
      }
    },
    isCrop(newValue) {
      this.onResizeVideo(this.resizeDimension, newValue);
    },
    videoSubtitle() {
      // this.currentSubtitle.data.subtitle = "";
      this.isPlaying = false;
    },
    clipStyle() {
      // Create a new style element
      if (this.clipStyle.caption_highlight) {
        const style = document.createElement("style");
        style.setAttribute("id", "custom-style");
        style.innerHTML = `.custom { color:${this.clipStyle.highlight_color}; }`;

        // Append the style element to the document's head
        document.head.appendChild(style);
      } else {
        const existingStyle = document.getElementById("custom-style");
        if (existingStyle) {
          const t = existingStyle.parentNode.removeChild(existingStyle);
        }
      }
    },
  },
  beforeDestroy() {
    this.resetCurrentSubtitle();
    this.setCurrentSubtitle("");
    if (this.player) {
      this.player?.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";
.customVideoPlayer1-dimensions{
  .vjs-control-bar {
    visibility: visible !important;
    display:flex !important;
}
}

.video-player1 {
  position: relative;

  &__player-icons {
    i {
      font-size: 80px !important;
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      z-index: 100;
      cursor: pointer;
    }
  }
  &__progress-icons {
    i {
      font-size: 20px !important;
      cursor: pointer;
      padding: 6px 12px;
    }
  }
  video,
  .video-js {
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    object-fit: contain;
    max-height: 720px !important;
    margin: auto;
    &.edit-page {
      max-height: 540px !important;
    }
    &.edit-page-resize {
      min-height: 570px;
    }

    .vjs-tech {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &__subtitle {
    color: white;
    background-color: $floating-upload-background;
    position: absolute;
    top: 0;
  }
  .fake-video-layer1 {
    position: absolute !important;
    margin: auto !important;
    margin-top: 0 !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    outline: none;
  }

  #fake-colored-layer-1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  #fake-colored-layer-2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #center-vertical-line1 {
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid $yellow;
    z-index: 200;
    display: none;
    left: 50%;
    height: 100%;
  }
}
.fade-enter-to,
.fade-leave {
  transition: opacity 0.01s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.highlighted-range {
  position: absolute;
  height: 100%;
  top: 0;
  background-color: red; /* Set the desired color for the highlighted ranges */
  opacity: 0.5;
  transform-origin: 0;
}
.progress-slider1 {
  height: 7px;
}
.start-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px; /* Adjust the width as needed */
  height: 100%;
  background-color: black; /* Adjust the color as needed */
  z-index: 2; /* Ensure the vertical bar is in front of the highlight */
}
</style>
