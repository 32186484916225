var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['video-player1', { 'video-player-view': !_vm.isEditPage }],attrs:{"id":"video-player1"}},[_c('div',{on:{"mouseover":function($event){return _vm.setShowIcon(true)},"mouseleave":function($event){return _vm.setShowIcon(false)}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showIcon && !_vm.isWaiting)?_c('div',{staticClass:"video-player1__player-icons",on:{"click":_vm.playVideo}},[(_vm.isPlaying)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-pause ")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-play ")])],1):_vm._e()]),_c('video',{ref:"videoPlayer1",class:[
        'video-js vjs-chopcast-theme',
        'vjs-16-9',
        ( _obj = {}, _obj[_vm.className] = _vm.isEditPage, _obj ) ],attrs:{"id":"customVideoPlayer1","preload":"metadata","crossorigin":"anonymous"},on:{"timeupdate":_vm.timeupdate}},[_c('track',{ref:"vttTrack",attrs:{"id":"trackel","kind":"captions","src":_vm.subtitleVttLink,"srclang":"en","label":"English","default":""}})]),_c('div',{style:({
        position: 'absolute',
        left: 0,
        zIndex: 101,
        alignItems: 'center',
        width: '100%',
        height: '7px',
      }),attrs:{"id":"fake-video-progress1"},on:{"click":_vm.focusFakePlayer}},[_c('div',{style:({
          position: 'relative',
          width: '100%',
          cursor: 'pointer',
          height: '100%',
        })},[(_vm.videoDuration)?_c('v-progress-linear',{staticClass:"progress-slider",attrs:{"color":"white","value":_vm.videoProgressBar,"height":"7","rounded":""},on:{"change":_vm.getVideoProgressValue,"mousemove":_vm.getProgressHoverValue,"mouseleave":function($event){_vm.hoveredTime = 0}}},[_vm._l((_vm.clipsList),function(clip){return [(
                _vm.$route.params.clipUuid && clip.uuid === _vm.$route.params.clipUuid
              )?_c('div',{key:clip.start_time,staticClass:"highlighted-range",staticStyle:{"background-color":"orange","opacity":"0.5"},style:({
                left: (((clip.start_time / _vm.videoDuration) * 100) + "%"),
                width: ((((clip.end_time - clip.start_time) / _vm.videoDuration) * 100) + "%"),
              })},[_c('div',{key:clip.start_time,staticClass:"start-bar"})]):_vm._e(),(!_vm.$route.params.clipUuid)?_c('div',{key:clip.start_time,staticClass:"highlighted-range",staticStyle:{"background-color":"orange","opacity":"0.5"},style:({
                left: (((clip.start_time / _vm.videoDuration) * 100) + "%"),
                width: ((((clip.end_time - clip.start_time) / _vm.videoDuration) * 100) + "%"),
              })},[_c('div',{key:clip.start_time,staticClass:"start-bar"})]):_vm._e()]})],2):_vm._e(),(_vm.isEditPage)?_c('v-progress-linear',{staticClass:"progress-slider1",attrs:{"value":_vm.fakeProgressValue,"color":"orange","height":"7","rounded":""},on:{"change":_vm.getProgressLinearValue,"mousemove":_vm.getHoveredValue,"mouseleave":function($event){_vm.hoveredTime = 0}}},[(_vm.autoCrop && _vm.autoCropCuts)?[_vm._l((_vm.autoCropCuts),function(range){return [_c('div',{key:range.start,staticClass:"highlighted-range",style:(("left: " + (((range.start - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip) /
                    (_vm.autoCrop.clip_end_time_relatedto_new_buffered_clip - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                  100) + "%; width: " + (((range.end - range.start) /
                    (_vm.autoCrop.clip_end_time_relatedto_new_buffered_clip - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                  100) + "%;"))})]})]:_vm._e(),(_vm.autoCrop && _vm.clip.audio_cleaned)?[_vm._l((_vm.audioFeatureCuts),function(range){return [_c('div',{key:range.start,staticClass:"highlighted-range",style:(("left: " + (((range.start - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip) /
                    (_vm.autoCrop.clip_end_time_relatedto_new_buffered_clip - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                  100) + "%; width: " + (((range.end - range.start) /
                    (_vm.autoCrop.clip_end_time_relatedto_new_buffered_clip - _vm.autoCrop.clip_start_time_relatedto_new_buffered_clip)) *
                  100) + "%;"))})]})]:_vm._e()],2):_vm._e(),_c('div',{staticStyle:{"display":"flex","align-items":"center","max-width":"90%","width":"100%"}},[_c('div',{staticClass:"video-player1__progress-icons",on:{"click":_vm.playVideo}},[(_vm.isPlaying)?_c('v-icon',{attrs:{"color":"white","size":"6px"}},[_vm._v(" mdi-pause ")]):_c('v-icon',{attrs:{"color":"white","size":"small"}},[_vm._v(" mdi-play ")])],1),_c('div',{style:({
              color: 'white',
              fontSize: '14px',
              padding: '4px',
              minWidth: '100px',
              textAlign: 'center',
              userSelect: 'none',
            }),attrs:{"draggable":false}},[_vm._v(" "+_vm._s(((_vm.playTime()) + "/" + (_vm.videoTime())))+" ")]),(_vm.hoveredTime)?_c('div',{style:({
              left: _vm.widthValueAtHover + 'px',
              top: '-35px',
              position: 'absolute',
              background: '#030303',
              opacity: '0.8',
              color: 'white',
              zIndex: 200,
              fontSize: '14px',
              padding: '4px',
              borderRadius: '4px',
            }),attrs:{"id":"video-time-tooltip"}},[_c('span',[_vm._v(_vm._s(_vm.hoveredTime))])]):_vm._e()])],1)]),_c('div',{staticClass:"fake-video-layer1",attrs:{"id":"fake-video-layer1","tabindex":"1"}},[_vm._l((_vm.viewedTitles),function(title){return _c('TitleText',{key:title.uuid + title.font_size,attrs:{"title":title,"isEditPage":_vm.isEditPage,"id":title.uuid,"resizeDimension":_vm.resizeDimension,"isCrop":_vm.isCrop,"fetchCurrentClip":_vm.fetchCurrentClip,"resizeCalled":_vm.resizeCalled,"isFakeVideoLayerFilled":_vm.isFakeVideoLayerFilled}})}),(_vm.isPlayerRendered && _vm.isFakeVideoLayerFilled)?_c('SubtitleText',{attrs:{"text":_vm.cuestring,"clipStyle":_vm.clipStyle,"videoDim":_vm.vidDim(),"createClip":_vm.updateCurrentClip,"video":_vm.video,"isEditPage":_vm.isEditPage,"resizeDimension":_vm.resizeDimension,"isCrop":_vm.isCrop,"fetchCurrentClip":_vm.fetchCurrentClip}}):_vm._e(),_vm._l((_vm.viewedImageLayers),function(image){return (_vm.isPlayerRendered && _vm.isFakeVideoLayerFilled)?_c('ImageLayer',{key:image.uuid,attrs:{"imageLayer":image,"isEditPage":_vm.isEditPage,"resizeDimension":_vm.resizeDimension,"isCrop":_vm.isCrop,"fetchCurrentClip":_vm.fetchCurrentClip,"resizeCalled":_vm.resizeCalled,"showPlayer":_vm.showPlayer,"player":_vm.player}}):_vm._e()}),_c('div',{attrs:{"id":"fake-colored-layer-1"}}),_c('div',{attrs:{"id":"fake-colored-layer-2"}}),_c('div',{attrs:{"id":"center-vertical-line1"}}),(_vm.isWaiting && !_vm.isAutoCropStarted)?_c('div',{staticClass:"d-flex align-center justify-center loader-wrapper",style:({ position: 'absolute', left:'47%', top:'47%'})},[_c('v-progress-circular',{attrs:{"size":46,"color":"blue-grey","indeterminate":""}})],1):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }