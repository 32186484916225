<template>
  <VueDragResize
    v-if="showPlayer && width"
    class="image-layer-wrapper vdr"
    :active="true"
    :parent="true"
    :resizable="isEditPage"
    :draggable="isEditPage"
    :lockAspectRatio="true"
    :w="width"
    :h="height"
    :x="left"
    :y="top"
    @dragstop="changeStatus"
    @resizestop="changeStatus"
    @activated="onTitleClicked"
  >
    <img
      :src="imageLayer.image"
      :class="[{ 'image-layer-wrapper__editable': isEditPage }]"
      alt="image-layer"
    />
    <v-icon
      v-if="isEditPage"
      class="image-layer-wrapper__delete"
      size="18"
      @click="deleteImageLayer"
      >mdi-close
    </v-icon>
  </VueDragResize>
</template>

<script>
import VueDragResize from "../Atoms/VueDraggableResizable";
import { mapActions, mapMutations, mapState } from "vuex";
import calculateLayerPosition from "../../utils/LayerPosition";
import types from "../../store/types";

export default {
  props: {
    imageLayer: Object,
    isEditPage: Boolean,
    resizeDimension: String,
    isCrop: Boolean,
    fetchCurrentClip: Function,
    showPlayer:Boolean,
    resizeCalled:Boolean
  },
  data() {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      isSetPosition: false,
      parentWidth: 0,
      parentHeight: 0
    };
  },
  components: {
    VueDragResize,
  },
  computed: {
    ...mapState({
      selectedTitleUuid: (state) => state.project.selectedTitleUuid,
      clip: (state) => state.project.clip,
      showAutoCrop: (state) => state.autoCrop.showAutoCrop
    }),
  },
  mounted() {
    this.setLayerPosition();
  },
  watch: {
    // imageLayer() {
    //   this.setLayerPosition();
    // },
    resizeDimension() {
      this.setLayerPosition();
    },
    isCrop() {
      this.setLayerPosition();
    },
    resizeCalled() {
      this.updateImageLayerOnResize()
    }
  },
  methods: {
    ...mapActions({
      updateImageLayerAction: types.project.actions.UPDATE_IMAGE_LAYER,
      deleteImageLayerAction: types.project.actions.DELETE_IMAGE_LAYER,
    }),
    ...mapMutations({
      setSelectedImageUuid:
        types.project.mutations.SET_SELECTED_IMAGE_LAYER_UUID,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    setLayerPosition() {
      this.isSetPosition = true;
      const { parentWidth, parentHeight, top, left, width, height } =
        calculateLayerPosition(this.imageLayer, "image");
        const videoDim = document.getElementById(this.showAutoCrop?"fake-video-layer1":"fake-video-layer");
      this.parentWidth = parentWidth;
      this.parentHeight = parentHeight;
      this.top = top;
      this.left = left;
      if(((((width >= videoDim.clientWidth || height >= videoDim.clientHeight) && (this.showPlayer || !this.clip.resize_type)) || !this.clip.resize_type || this.clip.resize_type === 'crop' || this.clip.resize_type === 'fit'))){
        this.width = (width/this.clip.width)*videoDim.clientWidth;
        this.height = (height/this.clip.height)*videoDim.clientHeight;
      }
      else if(width <=150 && height<=83 ) {
        this.width = width;
        this.height = height;
      }
      setTimeout(() => {
        this.isSetPosition = false;
      }, 200);
    },
    onTitleClicked() {
      this.setSelectedImageUuid(this.imageLayer.uuid);
    },
    async changeStatus(newRect) {
      if (!this.isSetPosition) {
        const videoDim = document.getElementById(this.showAutoCrop?"fake-video-layer1":"fake-video-layer");
        const { width, height } = this.clip;

        const layer = {
          uuid: this.imageLayer.uuid,
          clip: this.imageLayer.clip,
          width: Math.round((newRect.width / videoDim.clientWidth) * width),
          height: Math.round((newRect.height /videoDim.clientHeight)*height),
          x_pos_decimal: newRect.left / videoDim.clientWidth,
          y_pos_decimal: newRect.top / videoDim.clientHeight,
          y_pos_string: null,
          x_pos_string: null,
        };
        if(Math.round(newRect.width) === Math.round(this.width) && Math.round(this.height) === Math.round(newRect.height) && this.imageLayer.x_pos_decimal === layer.x_pos_decimal && this.imageLayer.y_pos_decimal === layer.y_pos_decimal){
          return;
        }
        this.width = newRect.width;
        this.height = newRect.height;

        await this.updateImageLayerAction(layer);
        this.fetchCurrentClip();
      }
    },
    async updateImageLayerOnResize(){
    if(this.width && this.height){
        const layer = {
          uuid: this.imageLayer.uuid,
          clip: this.imageLayer.clip,
          width: Math.round(this.width),
          height: Math.round(this.height),
          x_pos_decimal: 0.1,
          y_pos_decimal: 0.1,
          y_pos_string: null,
          x_pos_string: null,
        };
        this.top = 0;
        this.left = 0;
        let res = await this.updateImageLayerAction(layer);
        if(res){
          const { parentWidth, parentHeight, top, left } =
        calculateLayerPosition(this.imageLayer, "image");
        this.parentWidth = parentWidth;
        this.parentHeight = parentHeight;
        this.top = top;
        this.left = left;
        this.fetchCurrentClip();
        }

    }
    },
    async deleteImageLayer() {
      await this.deleteImageLayerAction(this.imageLayer.uuid);
      this.fetchCurrentClip();
      if (this.imageLayer.uuid == this.selectedTitleUuid) {
        this.setSelectedImageUuid("");
      }
      this.setSuccessMessage("Image deleted successfully");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/imageLayer.scss";
</style>
