<template>
  <div class="resize-dialog-wrapper">
    <v-menu
      bottom
      offset-y
      content-class="resize-dialog-wrapper__list"
      min-width="314"
      :close-on-content-click="false"
      v-model="isClosed"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="resize-dialog-wrapper__resize-button"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          text
        >
          <span class="font-weight-normal text-capitalize">Resize</span>
          <v-icon size="20">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-radio-group v-model="selectedOption">
          <v-radio
            color="secondary"
            v-for="option in options"
            :key="option.text"
            :label="option.text"
            :value="option.value"
          ></v-radio>
        </v-radio-group>

        <v-row class="pa-0 ma-0">
          <v-col
            cols="12"
            class="resize-dialog-wrapper__is-fit d-flex align-start mb-5 justify-center"
            v-if="!isOriginalRatio"
          >
            <div
              :class="[
                'mr-5',
                { 'resize-dialog-wrapper__is-fit__inactive-tab': isCrop },
              ]"
            >
              Fit
            </div>
            <v-switch v-model="isCrop" color="primary" hide-details inset>
            </v-switch>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="crop-icon" v-bind="attrs" v-on="on" size="small">mdi-help</v-icon>
                <div
                  :class="[
                    'ml-5',
                    { 'resize-dialog-wrapper__is-fit__inactive-tab': !isCrop },
                  ]"
                >Crop
                </div>
              </template>
              <span>Click and drag the video to adjust the frame to your liking</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="d-flex justify-center"  v-if="!isOriginalRatio">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :class="['resize-dialog-wrapper__apply-button', 'font-weight-bold', 'text-capitalize',{'auto-crop':autoCropSelect}]"
                  text
                  :ripple="false"
                  small
                  @click="autoCropClip"
                >
              Auto Crop
              <v-icon class="crop-icon" style="margin-top: 0;" v-bind="attrs" v-on="on" size="small">mdi-help</v-icon>
            </v-btn>
              </template>
              <span>Click to auto crop this video</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="px-4 pt-0 pb-2">
            <v-btn
              class="resize-dialog-wrapper__apply-button font-weight-bold text-capitalize"
              text
              :ripple="false"
              block
              height="36"
              @click="selectRatio"
            >
              Apply
            </v-btn>
          </v-col>
        </v-row>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import types from "../../store/types";
export default {
  props: [
    "setResizeDimension",
    "resizeDimension",
    "setIsCrop",
    "isCropped",
    "videoAspectRatio",

  ],
  data() {
    return {
      isCrop: this.isCropped,
      options: [
        {
          text: "16:9",
          value: "vjs-16-9",
        },
        {
          text: "9:16",
          value: "vjs-9-16",
        },
        {
          text: "1:1",
          value: "vjs-1-1",
        },
        {
          text: "4:5",
          value: "vjs-4-5",
        },
      ],
      selectedOption: "",
      isClosed: false,
      autoCropSelect:false
    };
  },
  mounted() {
    if(this.isAutoCropStarted) {
      this.resetAutoCrop()
    }
    this.selectedOption = this.resizeDimension;
    this.isCrop = this.isCropped
  },
  computed: {
    ...mapState({
      autoCropProgress: (state) => state.autoCrop.autoCropProgress,
      showAutoCrop: (state) => {return state.autoCrop.showAutoCrop},
      isAutoCropStarted: (state) => state.autoCrop.isAutoCropStarted,
      clip: (state) => state.project.clip,
      autoCropClipState:(state) => state.autoCrop.autoCropClip
    }),
    isOriginalRatio() {
      return this.videoAspectRatio == this.selectedOption;
    },
    audioFeatures() {
      if(this.clip.audio_features){
        let features = Object.keys(this.clip.audio_features)
        const cuts = []
            features.forEach(k=>{
                this.clip.audio_features[k].forEach(feature=>{
                    if(feature){
                      if(this.clip.start_time + feature.start < this.clip.end_time && this.clip.start_time + feature.end < this.clip.end_time){
                        cuts.push({start:this.clip.start_time + feature.start, end:this.clip.start_time + feature.end})
                      }
                      
                    }
                })
            })
        return cuts
      }
      return []
    },
  },
  watch: {
    resizeDimension(newValue) {
      if (newValue != this.selectedOption) {
        this.selectedOption = newValue;
      }
    },
    isClosed(newValue) {
      if (!newValue) {
        if (this.selectedOption != this.resizeDimension) {
          this.selectedOption = this.resizeDimension;
        }
        if (this.isCrop != this.isCropped) {
          this.isCrop = this.isCropped;
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      setIsAutoCropping:types.autoCrop.mutations.AUTO_CROPPING,
      setAutoCropProgress:types.autoCrop.mutations.AUTO_CROPPING_PROGRESS,
      setShowAutoCropPlayer:types.autoCrop.mutations.SHOW_AUTO_CROP,
      setIsAutocropStarted:types.autoCrop.mutations.SET_IS_AUTO_CROP_STARTED,
      setAutoCropClip:types.autoCrop.mutations.SET_AUTO_CROP_CLIP
    }),
    ...mapActions({
      autoCrop: types.autoCrop.actions.AUTO_CROP,
      fetchAutocropClip: types.autoCrop.actions.FETCH_AUTO_CROP_CLIP,
      onlyPatchClip: types.project.actions.PATCH_CLIP_ONLY,
      updateAutoCropClip:types.autoCrop.actions.UPDATE_AUTO_CROP_CLIP
    }),
    selectRatio() {
      if(this.selectedOption === this.resizeDimension && this.isCrop === this.isCropped && !this.autoCropSelect){
        this.isClosed = false
        return
      }
      if(this.autoCropSelect){
          this.onAutoCropClip()
          let wrapper = document.getElementById("player-wrapper");
          let progress = document.querySelector(".vjs-control-bar");
          wrapper.style.visibility = 'hidden'
          progress.style.visibility = 'hidden'
      }
      else{
        this.applyRatioChanges()
        this.setIsAutoCropping(null)
      }
    },
    applyRatioChanges(dim){
      if(dim && this.autoCropSelect){
        this.onlyPatchClip({ is_last_state_auto_cropped:true, clipUuid: this.$route.params.clipUuid })
        this.setResizeDimension(dim, true);
        this.setIsCrop(true);
      }
      else{
        this.setIsAutoCropping(null)
        this.setShowAutoCropPlayer(false)
        this.setAutoCropClip(null)
        this.onlyPatchClip({ is_last_state_auto_cropped:false, clipUuid: this.$route.params.clipUuid })
        this.setResizeDimension(this.selectedOption, true);
        this.setIsCrop(this.isCrop);
      }
      // this.setResizeDimension(this.selectedOption, true);
      this.isClosed = false;
    },

    autoCropClip(){
      this.autoCropSelect=!this.autoCropSelect
      if(this.autoCropClip){
        this.isCrop = true
      }
     
    },
    async onAutoCropClip(){
      const numbers = this.selectedOption.match(/\d+/g);
      const result = numbers.join(':');
      await this.autoCrop({clipUuid:this.$route.params.clipUuid, aspectRatio:result})
      this.setIsAutoCropping({processing:true, dimension:result})
      this.setIsAutocropStarted(true)
      this.setShowAutoCropPlayer(true)
      this.checkAutoCropStatus(this.selectedOption, result)
      this.isClosed = false;
    },

    checkAutoCropStatus(dim, result){
     const interval = setInterval(async ()=>{
       const status = await this.fetchAutocropClip({clipUuid:this.$route.params.clipUuid, ratio:result})
       if(this.autoCropProgress < 90){
        this.setAutoCropProgress(this.autoCropProgress+15)
       }
       if(status.status === 'succeeded'){
        let cuts = []
        if(this.clip.clip_cuts){
          cuts = this.mapAutoCropCuts(this.clip.clip_cuts)
          await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:result, clip_cuts:cuts})
        }
        if(this.clip.audio_features && this.clip.cleaned){
          const cuts1 = cuts.concat(this.mapAudioFeatureCuts())
          await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:result, clip_cuts:cuts1})
        }
          this.setAutoCropProgress(100)
          this.applyRatioChanges(dim)
          clearInterval(interval)
        
       }
      }, 5000)
    },
    resetAutoCrop(){
      this.setIsAutocropStarted(false)
      this.setAutoCropProgress(15)
    },
    mapAutoCropCuts(cuts) {
    // Calculate the duration of the first subclip and the second subclip
    const subClipDuration2 = this.autoCropClipState.clip_end_time_relatedto_new_buffered_clip - this.autoCropClipState.clip_start_time_relatedto_new_buffered_clip;

    // Initialize an array to hold the mapped cuts for the second subclip
    const mappedCuts = [];

        // Iterate over each cut in the first subclip
        for (const cut of cuts) {
            const cutStart1 = cut.start;
            const cutEnd1 = cut.end;

            // Calculate the cut duration and its relative start position in the first subclip
            const cutDuration1 = cutEnd1 - cutStart1;
            const cutRelativeStart1 = cutStart1 - this.clip.start_time;

            // Calculate the relative start position of the cut in the second subclip
            const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

            // Calculate the start and end times of the cut in the second subclip
            const cutStart2 = this.autoCropClipState.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
            const cutEnd2 = cutStart2 + cutDuration1;

            // Add the mapped cut to the array
            mappedCuts.push({ start: cutStart2, end: cutEnd2 });
        }

        return mappedCuts;
    },
    mapAudioFeatureCuts(){
    // Calculate the duration of the first subclip and the second subclip
    const subClipDuration2 = this.autoCropClipState.clip_end_time_relatedto_new_buffered_clip - this.autoCropClipState.clip_start_time_relatedto_new_buffered_clip;

    // Initialize an array to hold the mapped cuts for the second subclip
    const mappedCuts = [];
      if(this.audioFeatures && this.audioFeatures.length){

        // Iterate over each cut in the first subclip
        for (const cut of this.audioFeatures) {
            const cutStart1 = cut.start;
            const cutEnd1 = cut.end;

            // Calculate the cut duration and its relative start position in the first subclip
            const cutDuration1 = cutEnd1 - cutStart1;
            const cutRelativeStart1 = cutStart1 - this.clip.start_time;

            // Calculate the relative start position of the cut in the second subclip
            const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

            // Calculate the start and end times of the cut in the second subclip
            const cutStart2 = this.autoCropClipState.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
            const cutEnd2 = cutStart2 + cutDuration1;

            // Add the mapped cut to the array
            mappedCuts.push({ start: cutStart2, end: cutEnd2 });
        }
      }
    return mappedCuts;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/videos/resize-dialog.scss";

.crop-icon{
  font-size: small;
  margin-top: 5px;
  background: black;
  color: white !important;
  border-radius: 50%;
  margin-left: 5px;
  padding-left: 0px;
  padding: 1px;
}
.auto-crop{
  background: #7dea7d !important;
}
</style>
