<template>
  <div class="title-wrapper">
    <div
      v-if="selectedTitleUuid"
      class="title-wrapper__format--row d-flex"
      :style="{ alignItems: 'center' }"
    >
      <div class="title-wrapper__format--font-family">
        <v-select
          v-model="fontFamily"
          :items="listOfFonts"
          menu-props="auto"
          label="Font"
          hide-details
          prepend-icon="mdi-format-color-text"
          single-line
          solo
          flat
          @change="updateFontFamily"
        ></v-select>
      </div>
      <div class="title-wrapper__format--font-size">
        <v-select
          v-model="fontSize"
          :items="fontSizeList"
          menu-props="auto"
          label="Size"
          hide-details
          single-line
          solo
          flat
          @change="updateFontSize"
        ></v-select>
      </div>
    </div>

    <div
      v-if="selectedTitleUuid"
      class="title-wrapper__content"
      id="title-content"
      v-html="titleText"
      ref="titleContent"
      contenteditable="true"
      @blur="updateTitleText"
    ></div>

    <div class="title-wrapper__add-text">
      <v-row class="justify-space-between">
        <v-col cols="12" class="title-wrapper__add-text__title d-flex">
          <img
            class="mt-1"
            src="@/assets/images/icons/text-vector.svg"
            alt="text-vector"
            width="19"
            height="15"
          />
          <p class="mb-0">Select text Style</p>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="px-0 py-0"
          v-for="example in titleExamples"
          :key="example.font"
        >
          <p
            class="title-wrapper__add-text__example mb-0"
            :style="{
              color: example.color,
              fontFamily: example.font + ' !important',
              fontSize: '20px',
            }"
            @click="createTitle(example)"
          >
            Cool Text Goes Here
          </p>
        </v-col>
      </v-row>
    </div>

    <div v-if="selectedTitleUuid" class="title-wrapper__font-size">
      <p class="title-wrapper__font-size__header mt-0">Select text color</p>
      <v-row class="title-wrapper__fonts-wrapper">
        <div
          v-for="color in colors"
          :key="color"
          class="title-wrapper__font-size__color"
          :style="{
            backgroundColor: color,
            border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
          }"
          @click="updateTitleColor(color)"
        ></div>
        <div class="title-wrapper__font-size__color-text">
          hex:
          <input
            id="color-hex"
            class="title-wrapper__font-size__color-text__hex pl-0 d-inline-block"
            v-model="selectedColor"
            contenteditable="true"
            @change="updateTitleColor"
          />
        </div>
      </v-row>
    </div>

    <div v-if="selectedTitleUuid" class="title-wrapper__font-size">
      <p class="title-wrapper__font-size__header">Add background color</p>
      <v-row class="title-wrapper__fonts-wrapper">
        <div
          v-for="color in backgroundColors"
          :key="color"
          :class="[
            'title-wrapper__font-size__color',
            { 'transparent-color': color === 'transparent' },
          ]"
          :style="{
            backgroundColor: color,
            border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
          }"
          @click="updateTitleBackground(color)"
        ></div>
        <div class="title-wrapper__font-size__color-text">
          hex:
          <input
            id="background-hex"
            class="title-wrapper__font-size__color-text__hex d-inline-block pl-0"
            v-model="selectedBackgroundColor"
            contenteditable="true"
            @change="updateTitleBackground"
          />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import types from "../../store/types";
import LoadFontInRunTime from "../../utils/LoadFontInRunTime";

export default {
  props: {
    createNewClip: Function,
    video: Object,
    fetchCurrentClip: Function,
    realClipDim: Object,
  },
  data() {
    return {
      fontFamily: "Arvo",
      fontSize: 20,
      fontSizeList: [8, 9, 10, 11, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96],
      titleText: "",
      colors: [
        "#7367F0",
        "#F06767",
        "#FAC94B",
        "#45C7FF",
        "#323232",
        "#FFFFFF",
        "#C1D5E2",
        "#CE9FFC",
        "#60A88D",
      ],
      selectedColor: "",
      backgroundColors: [
        "transparent",
        "#F06767",
        "#FAC94B",
        "#45C7FF",
        "#323232",
        "#FFFFFF",
        "#C1D5E2",
        "#CE9FFC",
        "#60A88D",
      ],
      selectedBackgroundColor: "",
      titleExamples: [
        {
          font: "Quicksand-Regular",
          color: "#C8A943",
          box_width: 208,
        },
        {
          font: "Abril-Fatface",
          color: "#C87B43",
          box_width: 204,
        },
        {
          font: "Bebas-Neue-Regular",
          color: "#6E43C8",
          box_width: 152,
        },
        {
          font: "Dosis-Bold",
          color: "#3A9B61",
          box_width: 170,
        },
        {
          font: "Josefin-Sans-Bold-Italic",
          color: "#C84343",
          box_width: 204,
        },
        {
          font: "Lato-Black-Italic",
          color: "#2C2C2D",
          box_width: 187,
        },
        {
          font: "Roboto-Medium",
          color: "#4390C8",
          box_width: 203,
        },
      ],
    };
  },
  watch: {
    selectedTitleUuid() {
      setTimeout(() => {
        this.changeTitleListener();
        this.changeSelectedColor();
        this.changeSelectedBackground();
      }, 100);
      this.setValues();
    },
  },
  computed: {
    ...mapState({
      fonts: (state) => state.project.fonts,
      selectedTitleUuid: (state) => state.project.selectedTitleUuid,
      titles: (state) => state.project.titles,
      showAutoCrop: (state) => state.autoCrop.showAutoCrop
    }),
    listOfFonts() {
      return this.fonts.map((font) => font.name);
    },
    selectedTitle: function () {
      return this.titles.find((title) => title.uuid == this.selectedTitleUuid);
    },
  },
  mounted() {
    this.changeTitleListener();
    this.changeSelectedColor();
    this.changeSelectedBackground();
    this.setValues();
    this.loadInitialFonts();
  },
  methods: {
    ...mapActions({
      createVideoTitle: types.project.actions.CREATE_TEXT_LAYER,
      updateVideoTitle: types.project.actions.UPDATE_TEXT_LAYER,
      createLocalLayer: types.project.actions.CREATE_TEMPRORY_TEXT_LAYER,
      updateTextLayerFontsize: types.project.actions.UPDATE_TEXT_LAYER_FONTSIZE,
    }),
    ...mapMutations({
      updateVideoTitleInStore: types.project.mutations.UPDATE_TEXT_LAYER,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    setValues() {
      if (this.selectedTitleUuid) {
        this.selectedColor = this.selectedTitle.color;
        this.selectedBackgroundColor = this.selectedTitle.background_color;
        this.fontFamily = this.selectedTitle.font;
        this.fontSize = this.selectedTitle.font_size;
        this.titleText = this.selectedTitle.text;
      }
    },
    loadInitialFonts() {
      this.titleExamples.forEach((example) => {
        const fontFamilyURL = this.fonts.find(
          (font) => font.name === example.font
        ).url;
        LoadFontInRunTime(example.font, fontFamilyURL);
      });
    },
    changeTitleListener() {
      if (document.getElementById("title-content")) {
        this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' || mutation.type === 'characterData') {
            this.selectedTitle.text = this.$refs.titleContent.innerText;
          }
        });
      });

      this.observer.observe(this.$refs.titleContent, {
        childList: true,
        characterData: true,
        subtree: true,
      });
      }
    },
    changeSelectedColor() {
      if (document.getElementById("color-hex")) {
        document
          .getElementById("color-hex")
          .addEventListener("DOMSubtreeModified", (event) => {
            this.selectedTitle.color =
              event.target.data || event.target.innerHTML;
          });
      }
    },
    changeSelectedBackground() {
      if (document.getElementById("background-hex")) {
        document
          .getElementById("background-hex")
          .addEventListener("DOMSubtreeModified", (event) => {
            this.selectedTitle.background_color =
              event.target.data || event.target.innerHTML;
          });
      }
    },
    async createTitle(example) {
      const { clipUuid } = this.$route.params;

      let payload = {
        clipUuid,
        ...example,
      };

      if (!clipUuid) {
        const clip = await this.createNewClip({
          start_time: 0,
          end_time: this.video.duration,
        });
        payload.clipUuid = clip.uuid;
      }
      this.createLocalLayer(payload);
      setTimeout(async () => {
        const { clientWidth: titleDomWidth, clientHeight: titleDomHeight } =
          document.getElementById("title-text");
        const {
          clientWidth: VideoPlayerWidth,
          clientHeight: VideoPlayerHeight,
        } = document.getElementById(this.showAutoCrop?"fake-video-layer1":"fake-video-layer");
        const { width, height } = this.realClipDim;
        const boxWidth = Math.round((titleDomWidth / VideoPlayerWidth) * width);
        const boxHeight = Math.round(
          (titleDomHeight / VideoPlayerHeight) * height
        );
        payload = { ...payload, box_width: boxWidth, box_height: boxHeight };
        await this.createVideoTitle(payload);
        this.fetchCurrentClip();
        this.setSuccessMessage("Text added successfully");
      });
    },
    updateFontFamily: function () {
      const payload = { font: this.fontFamily };
      this.updateTitle(payload);

      const fontFamilyURL = this.fonts.find(
        (font) => font.name === this.fontFamily
      ).url;
      LoadFontInRunTime(this.fontFamily, fontFamilyURL);
    },
    updateFontSize: function () {
      const payload = { font_size: this.fontSize };
      this.updateTextLayerFontsize({
        uuid: this.selectedTitle.uuid,
        font_size: this.fontSize,
      });
      this.updateTitle(payload);
    },
    updateTitleText: function () {
      this.titleText = this.selectedTitle.text;
      this.updateTitle({ text: this.selectedTitle.text });
    },
    updateTitleColor: function (e) {
      if(typeof e === 'object'){
        this.selectedColor = e.target.value;
        this.updateTitle({ color: e.target.value });
      }
      else{
        this.selectedColor = e;
        this.updateTitle({ color: e });
      }
    },
    updateTitleBackground: function (e) {
      if(typeof e === 'object'){
        this.selectedBackgroundColor = e.target.value;
        this.updateTitle({ background_color: e.target.value });
      }
      else{ 
        this.selectedBackgroundColor = e;
      let newColor = e;
      if (!e || e == "transparent") {
        newColor = null;
      }
      this.updateTitle({ background_color: newColor });
      }
    },
    updateTitle: function (payload) {
      if (this.selectedTitle) {
        setTimeout(async () => {
          const { clientWidth: titleDomWidth, clientHeight: titleDomHeight } =
            document.getElementById(this.selectedTitleUuid);
          const {
            clientWidth: VideoPlayerWidth,
            clientHeight: VideoPlayerHeight,
          } = document.getElementById(this.showAutoCrop?"fake-video-layer1":"fake-video-layer");

          const { width, height } = this.realClipDim;
          const boxWidth = Math.round(
            (titleDomWidth / VideoPlayerWidth) * width
          );
          const boxHeight = Math.round(
            (titleDomHeight / VideoPlayerHeight) * height
          );
          await this.updateVideoTitle({
            ...this.selectedTitle,
            ...payload,
            box_width: boxWidth,
            box_height: boxHeight,
          });

          this.fetchCurrentClip();
          this.setSuccessMessage("Text updated successfully");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/text.scss";
</style>
